import { validationMixin } from "vuelidate";
export default {
  mixins: [validationMixin],
  methods: {
    handleFormValidation(fieldName, vueObj, dataName = "formData", extraData) {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName]["$dirty"]) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push(this.$t("This field must be greater than 0"));
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push(this.$t("This url is invalid"));
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push(this.$t("This must be a number"));
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push(this.$t("This email address is invalid"));
      }
      if ("decimal" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].decimal &&
          errors.push(this.$t("This must be a decimal"));
        errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      if ("decimal" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].decimal &&
          errors.push("This must be a decimal");
      }
      if ("minLength" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].minLength &&
          errors.push(
            `${this.$t("Minimum valid input length is :num: character").replace(
              ":num:",
              extraData?.minLength
            )}`
          );
      }
      if ("maxLength" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxLength &&
          errors.push(
            `${this.$t("Maximum valid input length is :num: character").replace(
              ":num:",
              extraData?.maxLength
            )}`
          );
      }
      return errors;
    },
  },
  computed: {
    requiredRule() {
      return (fieldName, objectName = "formData") => {
        return this.handleFormValidation(fieldName, this, objectName);
      };
    },
  },
};
